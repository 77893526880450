<template>
    <div :class="`${LayoutType} ${Theme}`">
        <!-- ---------------------------------- -->
        <!--- Vertical Header part -->
        <!-- ---------------------------------- -->
        <!--<VerticalSidebar />-->
        <aside class="bg-landing horizontal-page-wrapper">
            <!--<VerticalHeader />-->
            <HorizontalHeader />
            <HorizontalSidebar />
            <div :class="setHorizontalLayout == true ? 'container-fluid p-4' : 'container-fluid p-4'">
                <router-view />
            </div>
            <Footer/>
        </aside>
    </div>
</template>

<script>
//import VerticalHeader from './vertical-header/VerticalHeader'
import HorizontalHeader from './horizontal-header/HorizontalHeader'
import HorizontalSidebar from './horizontal-sidebar/HorizontalSidebar'
//import VerticalSidebar from './vertical-sidebar/VerticalSidebar'
import Footer from './footer/Footer'
import {mapState} from 'vuex'
export default {
    name: 'Frontlayout',
    components:{
        //VerticalHeader,
        //VerticalSidebar,
        HorizontalSidebar,
        HorizontalHeader,
        Footer
    },
     data: () => ({
        // cpath: this.currentRoute.path,
        items: [
            {
                text: 'Dashboard',
                href: '/'
            },
            {
                text:'',
                active: true
            }
            
            ],
            
    }),
    computed:{
        ...mapState({
            LayoutType: (state) => state.setting.LayoutType,
            Theme: (state) => state.setting.Theme,
            setHorizontalLayout: (state) => state.setting.setHorizontalLayout
        }),
        currentRouteName() {
            return this.$route.name;
        }
    },
    methods: {
    //define below method first.
    winWidth: function () {
        setInterval(() => {
            var w = window.innerWidth;
            
            if (w < 992) {
                 this.$store.commit("SET_LAYOUT_TYPE", 'mini-sidebar');
            }  
        }, 100);
    }
    
},
    mounted() {
        //callback once mounted
        this.winWidth()
    },
    watch: {

    }
}
</script>

<style>
.bg-landing{
  background: url("../../assets/images/owrent-banner.jpg") no-repeat;
  background-size: 100% 550px;
  background-position: top;
}
</style>